import { useRoutes } from "react-router-dom";
import Account from "../Shop/Dashboard/Account";
import AccountHome from "../Shop/Dashboard/AccountHome";
import Orders from "../Shop/Dashboard/Orders";
// import Overview from "../Shop/Dashboard/Overview";
import Category from "../Shop/Category";
import ShopCart from "../Shop/ShopCart";
import ShopItems from "../Shop/ShopItems";
import ItemDetails from "../Shop/ItemDetails";
import Login from "../Auths/LogIn";
import SignUp from "../Auths/SignUp";
import AppIndex from "./AppIndex";
import ProductDetails from "../Shop/ProductDetails";
import ProductDet from "../Shop/ProductDet";
import ScrollToTop from "../utils/ScrollToTop";
import Terms_Cond from "../Shop/footer/Terms_Cond";



function AppNavigation() {
  let element = useRoutes([
    {
      path: "/",
      element: <AppIndex />,
      children: [
        { index: true, element: <ShopItems /> },
        {
          path: "/category/:category",
          element: <Category />,
        },
        {
          path: "/category/:category/:id",
          element: <ProductDet />,
        },
        {
          path: "/cart",
          element: <ShopCart />,
        },
        {
          path: "/logIn",
          element: <Login />,
        },
        {
          path: "/sign-up",
          element: <SignUp />,
        },
        {
          path: "/item/:id",
          element: <ProductDetails />,
        },
        { path: "/item-details", element: <ItemDetails /> },
        {
          path: "/terms & conditions",
          element: <Terms_Cond />,
        },
      ],
    },
    {
      path: "/account",
      element: <Account />,
      children: [
        { index: true, element: <AccountHome /> },
        {
          path: "orders",
          element: <Orders />,
        },
        {
          path: "overview",
          element: <Orders />,
        },
        {
          path: "settings",
          element: <Orders />,
        },
      ],
    },
  ]);
  return (
    <>
      <ScrollToTop /> 
      {element}
    </>
  );
  
}
export default AppNavigation;