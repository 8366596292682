import { React } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faCar,
  faPlane,
  faShop,
  faShoppingBag,
  faTv,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation, Autoplay, EffectFade } from "swiper/modules";
import "./shophomepage.css";
import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import item1 from "../../Images/item1.webp";
import item2 from "../../Images/item2.webp";
import item3 from "../../Images/item3.webp";
import item4 from "../../Images/item4.webp";
import item5 from "../../Images/item5.webp";
import carousel1 from "../../Images/carousel1.webp";
import carousel2 from "../../Images/2.jpg";
import carousel3 from "../../Images/1.jpg";
import carousel4 from "../../Images/3.jpg";
import carousel5 from "../../Images/carousel4.webp";
import carousel6 from "../../Images/carousel6.webp";
import subcarousel1 from "../../Images/subcrousel1.webp";
import subcarousel2 from "../../Images/subcarousel2.webp";
import subcarousel3 from "../../Images/subcarousel3.jpg";
import subcarousel4 from "../../Images/subcarousel4.webp";
import Image from "../../Images/images.jpeg";
import phone from "../../Images/phone.webp";
import appliances from "../../Images/appliances.png";
import baby from "../../Images/baby.webp";
import beauty from "../../Images/beauty.png";
import shoes from "../../Images/shoe.png";
import gaming from "../../Images/gaming.webp";
import health from "../../Images/health.webp";
import home from "../../Images/home.webp";
import electronics from "../../Images/speaker.webp";
import sport from "../../Images/sport.webp";
import drinks from "../../Images/drinks.webp";
import fabric from "../../Images/fabrics.png";
import { Home, PhoneCall, Trash } from "lucide-react";

export default function ShopHomepage() {
  const handleClick = () => {
    const newWindow = window.open(`product`);
    if (newWindow) {
      newWindow.focus();
    }
  };

  const navigate = useNavigate();

  const slides = [
    { id: 1, img: carousel1 },
    { id: 2, img: carousel2 },
    { id: 3, img: carousel3 },
    { id: 4, img: carousel4 },
    { id: 5, img: carousel5 },
    { id: 6, img: carousel6 },
  ];

  const subSlides = [
    { id: 1, img: subcarousel1 },
    { id: 2, img: subcarousel2 },
    // { id: 3, img: subcarousel3 },
    // { id: 4, img: subcarousel4 },
  ];

  const anotherslide = [
    {
      id: 2,
      info: (
        <div>
          <span className="d-flex mb-3" style={{ gap: "15px" }}>
            <div
              className="d-flex justify-content-center align-items-center p-2"
              style={{
                border: "2px solid #542b2b",
                borderRadius: "100px",
                width: "50px",
                height: "50px",
                color: "#542b2b",
              }}
            >
              <PhoneCall />
            </div>
            <div>
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                CALL TO ORDER
              </p>
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                07017222999 , 07030975118
              </p>
            </div>
          </span>
          <Link
            to={"https://app.kasuwamall.com/"}
            className="text-dark"
            style={{ textDecoration: "none" }}
          >
            <span className="d-flex mb-3" style={{ gap: "15px" }}>
              <div
                className="d-flex justify-content-center align-items-center p-2"
                style={{
                  border: "2px solid #542b2b",
                  borderRadius: "100px",
                  width: "50px",
                  height: "50px",
                  color: "#542b2b",
                }}
              >
                <Home />
              </div>
              <div className="d-flex justify-content-center align-items-center">
                <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                  SELL IN KASUWAMALL
                </p>
              </div>
            </span>
          </Link>
          <span className="d-flex " style={{ gap: "15px" }}>
            <div
              className="d-flex justify-content-center align-items-center p-2"
              style={{
                border: "2px solid #542b2b",
                borderRadius: "100px",
                width: "50px",
                height: "50px",
                color: "#542b2b",
              }}
            >
              <Trash />
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <p style={{ fontWeight: "600", padding: "0", margin: "0" }}>
                Best Deals
              </p>
            </div>
          </span>
        </div>
      ),
    },
  ];

  const viewers = [
    { id: 1, icon: faWallet, title: "Pay Bills" },
    { id: 2, icon: faShop, title: "Offline Shop" },
    { id: 3, icon: faPlane, title: "Book Flight" },
    { id: 4, icon: faCar, title: "Buy Cars" },
    { id: 5, icon: faShoppingBag, title: "Brand Shopping" },
    { id: 6, icon: faTv, title: "Kasua TV" },
  ];

  const todaysDeal = [
    {
      id: 1,
      img: item1,
      name: "Samsung S24",
      new: "300,000",
      prev: "330,000",
      percent: 10,
      save: "30,000",
    },
    {
      id: 2,
      img: item2,
      name: "Redmi 13C 128GB",
      new: "110,000",
      prev: "125,000",
      percent: 15,
      save: "15,000",
    },
    {
      id: 3,
      img: item4,
      name: "Juicer",
      new: "10,500",
      prev: "13,200",
      percent: 12,
      save: "2,700",
    },
    {
      id: 4,
      img: item5,
      name: "Sound System",
      new: "100,000",
      prev: "115,000",
      percent: 15,
      save: "15,000",
    },
    {
      id: 5,
      img: item3,
      name: "Hisense",
      new: "230,000",
      prev: "260,000",
      percent: 9,
      save: "30,000",
    },
    {
      id: 6,
      img: item2,
      name: "Redmi 13C 256GB",
      new: "125,999",
      prev: "145,000",
      percent: 18,
      save: "19,000",
    },
  ];
  const sponsored = [
    { id: 1, img: item1, name: "Samsung S24 ultra", price: "310,000" },
    { id: 2, img: item5, name: "Sound system", price: "105,000" },
    { id: 3, img: item3, name: "Hisense '16 TV", price: "250,000" },
    { id: 4, img: item4, name: "Juicer", price: "11,100" },
    { id: 5, img: item1, name: "Samsung S24", price: "220,000" },
    { id: 6, img: item2, name: "Redmi 13C 128GB", price: "115,000" },
  ];

  const category = [
    { id: 1, image: appliances, title: "Appliances", name: "appliances" },
    { id: 2, image: phone, title: "Phones & Tablets", name: "gadgets" },
    { id: 3, image: beauty, title: "Beauty", name: "beauty" },
    { id: 4, image: electronics, title: "Electronics", name: "electronics" },
    { id: 5, image: shoes, title: "Fashion", name: "fashion" },
    { id: 6, image: baby, title: "Baby Products", name: "baby-Product" },
    { id: 7, image: gaming, title: "Gaming", name: "gaming" },
    { id: 8, image: drinks, title: "Drinks", name: "drinks" },
    { id: 9, image: home, title: "Home & Office", name: "home&office" },
    { id: 10, image: health, title: "Health", name: "health" },
    { id: 11, image: fabric, title: "Fabrics", name: "fabric" },
    { id: 12, image: sport, title: "Sporting Goods", name: "sporting" },
  ];

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  const delivery = [
    { id: 1, img: item1, name: "Samsung S24 ultra", price: "310,000" },
    { id: 2, img: item2, name: "Sound system", price: "105,000" },
    { id: 3, img: item3, name: "Hisense '16 TV", price: "250,000" },
    { id: 4, img: item4, name: "Juicer", price: "11,100" },
    { id: 5, img: item5, name: "Samsung S24", price: "220,000" },
    { id: 6, img: item2, name: "Redmi 13C 128GB", price: "115,000" },
  ];

  return (
    <>
      {/* This is the jumbotron part of the code where the slider and sub component */}
      <div className="pt-5 container-mod container-fluid">
        <div className=" row mb-4">
          <Col lg={2} md={6} className="d-lg-block" style={{ display: "none" }}>
            <div className="row">
              {subSlides.map((subSlide) => (
                <Col md={12} className="sub-carousel p-1" key={subSlide.id}>
                  <div className="sub-slide-background rounded w-100 h-100">
                    <img src={subSlide.img} className="w-100 h-100 rounded" />
                  </div>
                </Col>
              ))}
            </div>
          </Col>
          <Col lg={7} md={8} className="mySwiper-col">
            {/* swipper part */}
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              effect={"fade"}
              navigation={true}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 4000,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, Autoplay, EffectFade]}
              className="mySwiper rounded h-100"
            >
              {slides.map((slide) => (
                <SwiperSlide
                  className="slide-background h-100 w-100"
                  key={slide.id}
                >
                  <img
                    src={slide.img}
                    className="w-100 h-100"
                    style={{ objectFit: "fill" }}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>

          <Col
            className="d-lg-none"
            style={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)" }}
          >
            <div className="row">
              {subSlides.map((subSlide) => (
                <Col className="sub-carousel p-1" key={subSlide.id}>
                  <div className="sub-slide-background rounded w-100 h-100">
                    <img
                      src={subSlide.img}
                      className="w-100 h-100  h-sm-70 rounded"
                    />
                  </div>
                </Col>
              ))}
            </div>
          </Col>

          {/* components part */}
          <Col lg={3} md={3} className="d-lg-block" style={{ display: "none" }}>
            <Card className="row p-3">
              {anotherslide.map((anotherslide) => (
                <Col md={12} className="sub-carousel p-1" key={anotherslide.id}>
                  <div className="">{anotherslide.info}</div>
                </Col>
              ))}
            </Card>
          </Col>
        </div>

        {/* This is where the viewers choice is, it is only visible on large screen */}
        <div className=" row mx-0 collections">
          {viewers.map((view) => (
            <Col
              md={2}
              sm={2}
              className="px-1 d-flex justify-content-center"
              key={view.id}
            >
              <div className="rounded shadow w-100 d-flex justify-content-center align-items-center py-2 px-3 collections-text">
                <FontAwesomeIcon icon={view.icon} className="me-2" />
                {view.title}
              </div>
            </Col>
          ))}
        </div>

        <Card className="mt-3 shop-main-card p-4 rounded">
          <h4>Top Selling Categories </h4>
          <Row className="card-img-hide-col">
            {category.map((card) => (
              <Col lg={2} md={3} sm={6} xs={12} className="p-1 " key={card.id}>
                <Card
                  className="border-0 card-hover show"
                  onClick={() => handleCategoryClick(card.name)}
                >
                  <CardImg
                    variant="top"
                    src={card.image}
                    className="card-img-hide rounded-circle"
                  />
                  <CardBody className="p-1">
                    <CardTitle className="text-center">{card.title}</CardTitle>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Card>

        <div className="industry-list">
          <div className="slider-arrow-wrapper prev"></div>
          <div className=""></div>
          <div className="slider-arrow-wrapper prev"></div>
        </div>

        {/* This is the part where the product to be displayed are. */}
        {/* <div className="row mx-0 mt-4">
          <Card className="px-0 border-0">
            <CardHeader className="bg-secondary w-100 py-2 d-flex align-items-center card-header-bg">
              <span className="fw-bold fs-large header-size me-3">
                Today's Deal
              </span>
              <Link to="#" className="link-size">
                See more items
              </Link>
            </CardHeader>
            <CardBody className="p-2">
              <div className="row mx-0 scrollable-row">
                {todaysDeal.map((product) => (
                  <Col lg={4} md={6} className="py-2 px-2">
                    <div onClick={handleClick} className="d-flex flex-sm-column flex-md-column flex-lg-row justify-content-center align-items-center shadow-sm py-4 px-2 card-hoverable">
                      <img
                        src={product.img}
                        style={{ width: "25%", height: "6.5rem" }}
                        className="me-lg-3"
                      />
                      <div className="d-flex flex-column w-75">
                        <p>
                          {product.name.length > 16
                            ? `${product.name.substring(0, 10)}...`
                            : product.name}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="main-price">₦{product.new}</span>
                          <span>
                            ₦<small className="canceled-price">{product.prev}</small>
                          </span>
                          <span className="badge bg-danger rounded-pill ">
                            -{product.percent}%
                          </span>
                        </div>
                        <b className="text-success link-size">you save ₦{product.save}</b>
                      </div>
                    </div>
                  </Col>
                ))}
              </div>
            </CardBody>
          </Card>
        </div> */}

        {/* This part is where you see the sponsored product */}
        {/* <div className=" mt-4">
          <div className="fw-bold fs-large header-size-2 ms-lg-4 ms-md-3 text-dark">
            Sponsored Product
          </div>
          <div className="border-bottom border-2"></div>
          <section>
            <div className="row mx-0 scrollable-row-permanant">
              {sponsored.map((sponsor) => (
                <Col lg={4} md={6} sm={6} className="py-2 px-2 sponsored-items">
                  <Card onClick={handleClick} className="w-100 px-2 border-0 shadow-sm card-hoverable">
                    <CardImg src={sponsor.img} className="p-3" />
                    <CardBody className="pb-4 pt-0 px-2">
                      <CardSubtitle className="fw-bold">
                        {sponsor.name.length > 16
                          ? `${sponsor.name.substring(0, 10)}...`
                          : sponsor.name
                        }
                      </CardSubtitle>
                      <CardText>₦{sponsor.price}</CardText>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </div>
          </section>
        </div> */}

        {/* Same day delivery */}
        {/* <div className="row mx-0 mt-4">
          <Card className="px-0 border-0" style={{boxShadow: "none"}}>
            <CardHeader className="w-100 py-1 d-flex align-items-center card-header-bg-2">
              <span className="fw-bold header-size-2 me-3">
                Same Day Delivery (KasuaNow)
              </span>
              <Link to="#" className="link-color">
                See more items
              </Link>
            </CardHeader>
            <CardBody className="p-2" style={{ backgroundColor: "#efefefef" }}>
              <section>
                <div className="row mx-0 scrollable-row-permanant">
                  {delivery.map((item) => (
                    <Col lg={4} md={6} sm={6} className="py-2 px-2 sponsored-items" key={item.id}>
                      <Card onClick={handleClick} className="w-100 px-2 border-0 shadow-sm card-hoverable">
                        <CardImg src={item.img} className="p-3" />
                        <CardBody className="pb-4 pt-0 px-2">
                          <CardSubtitle className="fw-bold">
                            {item.name && item.name.length > 16
                              ? `${item.name.substring(0, 10)}...`
                              : item.name || "No Name"}
                          </CardSubtitle>
                          <CardText>₦{item.price}</CardText>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </div>
              </section>
            </CardBody>
          </Card>
        </div> */}
      </div>
    </>
  );
}
