import React, { useState, useContext, useEffect } from "react";
import {
  ChevronDown,
  HelpCircle,
  Search,
  ShoppingCart,
  User,
} from "react-feather";
// import { Package2 } from "lucide-react";
import { CiSearch } from "react-icons/ci";
import { GiHamburgerMenu } from "react-icons/gi";
import { LuUserCircle2 } from "react-icons/lu";
import { MdOutlineShoppingCart } from "react-icons/md";
import {
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  NavItem,
  Row,
} from "reactstrap";
import ProductDetails from "./ProductDetails";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../redux/action/auth";
import AuthModal from "../Auths/AuthModal";
import Brand from "../Images/download.png";
import Brand2 from "../Images/download 2.png";
import "./ShopIndex.css";
import "./shop.css";
import { Link } from "react-router-dom";
import { globalColor, useWindowWidth } from "../utils/Helper";
import KasuwaLogo from "../Images/KASUWAMALL (2).png";

export default function ShopNavbar() {
  const {
    shop: { carts },
    auth: { user, authenticated },
  } = useSelector((s) => s);
  const [auth_type, setAuthType] = useState("Login");
  const [currentSize, setCurrentSize] = useState(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const [open1, setOpen1] = useState(false);
  const toggle1 = () => {
    setOpen1(!open1);
  };
  const [modal, setModal] = useState(false);
  const toggleModal = () => setModal(!modal);

  const width = useWindowWidth();
  // useEffect (() => {
  //   if (width<768)
  // },[])

  // Define the dropdown state for each category
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const cart = () => {
    let data = localStorage.getItem("cart");
    return data;
  };

  const [hoveredCategory, setHoveredCategory] = useState(null);

  const navigate = useNavigate();

  const categories = [
    {
      id: 1,
      title: "All Categories",
      items: ["Computers and Accessories", "Phones and Tablets", "Electronics"],
    },
    {
      id: 2,
      title: "Fabrics",
      name: "fabric",
      items: ["Lace", "sharda", "Gizna"],
    },

    {
      id: 3,
      title: "Phones and Tablets",
      name: "gadgets",
      items: ["Smartphones", "Tablets", "Accessories"],
    },
    {
      id: 4,
      title: "Electronics",
      name: "electronics",
      items: ["TVs", "Home Audio", "Cameras"],
    },
    {
      id: 6,
      title: "Home and Office",
      name: "home&office",
      items: ["Furniture", "Appliances", "Cookware"],
    },
    {
      id: 7,
      title: "Baby, Kids and Toys",
      name: "baby-Product",
      items: ["Toys", "Kids' Furniture", "Baby Gear"],
    },
    {
      id: 8,
      title: "Computers and Accessories",
      name: "gadgets",
      items: ["Laptops", "Desktops", "Accessories"],
    },
  ];

  const handleCategoryClick = (category) => {
    navigate(`/category/${category}`);
  };

  return (
    <div className="">
      {width < 768 ? (
        <Row
          className="px-3 small-size-navbar"
          style={{ backgroundColor: globalColor.color3 }}
        >
          <Col
            md={12}
            className="d-flex align-items-center justify-content-between py-3 px-2"
          >
            <div className="d-flex align-items-center">
              <GiHamburgerMenu
                style={{ fontSize: "28px", color: globalColor.color2 }}
                className="me-3"
              />
              <Link
                className="logo-text text-decoration-none"
                style={{ color: globalColor.color2, fontWeight: "bold" }}
                to={"/"}
              >
                <strong style={{}}>KASUWAMALL</strong>
              </Link>
            </div>
            <div className="d-flex align-items-center">
              <LuUserCircle2
                onClick={() => toggleModal()}
                style={{ fontSize: "30px", color: globalColor.color2 }}
                className="me-4"
              />
              <div
                className="position-relative"
                onClick={() => navigate("/cart")}
              >
                <MdOutlineShoppingCart
                  style={{ fontSize: "30px", color: globalColor.color2 }}
                  className="w-100"
                />
                <Badge
                  color="secondary"
                  className="rounded-circle cart-badge position-absolute"
                  style={{ color: globalColor.color2 }}
                >
                  {carts.length > 0
                    ? carts.reduce((p, c) => p + parseInt(c.qty), 0)
                    : 0}
                </Badge>
              </div>
            </div>
          </Col>
          <Col md={12} className="pb-3">
            <div className="position-relative">
              <Input className="rounded-pill small-screen-search-input" />
              <CiSearch
                size={24}
                className="small-screen-search position-absolute"
                style={{}}
              />
            </div>
          </Col>
        </Row>
      ) : (
        <Row className="nav-row">
          {/* <Col md={2} className="d-flex ">
            <Link
              className=""
              style={{ fontWeight: "800", color: "white" }}
              to={"/"}
            >
              KASUWA MALL
            </Link>
          </Col> */}
          <Col md={2} className="col1 d-flex ">
            <Link
              className="logo-text"
              style={{
                color: globalColor.color3,
                height: "50px",
                width: "100px",
              }}
              to={"/"}
            >
              <img
                src={KasuwaLogo}
                alt="kasuwamall logo"
                style={{ width: "100%", height: "100%" }}
              />
            </Link>
          </Col>

          <Col md={6} className="col2 text-center d-flex align-items-center">
            <div className="search w-100 justify-content-center">
              <div className="search-container">
                <input type="text" placeholder="Search item here" />
                <button
                  type="submit"
                  className="rounded-end search-icon-button"
                  style={{}}
                >
                  <CiSearch
                    size={20}
                    className="search-icon-button"
                    style={{}}
                  />
                </button>
              </div>
            </div>
          </Col>

          <Col md={4} className="col3">
            <nav className="h-100">
              <ul className="nav-list mt-0 py-md-3 h-100">
                <li className="nav-item medium-search-button">
                  <button
                    type="submit"
                    className="rounded border-0 py-1 px-2 search-icon-button"
                  >
                    <CiSearch size={20} className="search-icon-button" />
                  </button>
                </li>
                <li className="nav-item d-flex align-items-center">
                  {authenticated ? (
                    <Dropdown
                      className="dropdown"
                      isOpen={open}
                      toggle={toggle}
                    >
                      <DropdownToggle
                        className="dropdown-toggle"
                        style={{
                          backgroundColor: "white",
                          border: "none",
                          color: "rgb(99, 99, 99)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <User size="1.5em" /> {user.username || user.email}{" "}
                        <ChevronDown />
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu">
                        <DropdownItem
                          className="dropdown-item"
                          onClick={() => navigate("/account")}
                        >
                          Account
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => navigate("/account/orders")}
                          className="dropdown-item"
                        >
                          Orders
                        </DropdownItem>
                        <DropdownItem className="dropdown-item" divider />
                        <DropdownItem
                          onClick={() => dispatch(logout())}
                          className="dropdown-item"
                        >
                          Sign Out
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  ) : (
                    <NavItem className="">
                      <Button
                        onClick={() => toggleModal()}
                        className="btn btn-for-signUp3 mb-0"
                      >
                        Login
                      </Button>
                    </NavItem>
                  )}
                </li>
                <li className="nav-item help">
                  <Dropdown
                    className="dropdown"
                    isOpen={open1}
                    toggle={toggle1}
                  >
                    <DropdownToggle
                      className="dropdown-toggle"
                      style={{
                        backgroundColor: "",
                        border: "none",
                        color: "rgb(99, 99, 99)",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <HelpCircle size="1.5em" className="me-1" /> Help
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu">
                      <DropdownItem className="dropdown-item">
                        Settings
                      </DropdownItem>
                      <DropdownItem className="dropdown-item">
                        Help & Support
                      </DropdownItem>
                      <DropdownItem className="dropdown-item" divider />
                      <DropdownItem className="dropdown-item">
                        About us
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </li>
                <li
                  className="nav-item cart-icon"
                  onClick={() => navigate("/cart")}
                >
                  <ShoppingCart size="1.5em" /> Cart
                  <span className="cart-count">
                    {carts.length > 0
                      ? carts.reduce((p, c) => p + parseInt(c.qty), 0)
                      : 0}
                  </span>
                </li>
              </ul>
            </nav>
          </Col>
          <section className="mx-0 px-0 category-navbar-container">
            <div
              className="d-flex justify-content-center scrollable-row category-div"
              style={{}}
            >
              {categories.map((category) => (
                <div
                  className="px-lg-4 px-md-3 py-2 text-white category-button"
                  style={{ fontSize: "18px" }}
                  key={category.id}
                  onMouseEnter={() => setHoveredCategory(category.id)}
                  onMouseLeave={() => setHoveredCategory(null)}
                  onClick={() => handleCategoryClick(category.name)}
                >
                  {category.title}
                  {/* {hoveredCategory === category.id && (
                    <div className="category-menu">
                      <Row className="row mx-0">
                        {category.items.map((item, index) => (
                          <Col lg={3} md={4} key={index}>
                            <b>{item}</b>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )} */}
                </div>
              ))}
            </div>
          </section>
        </Row>
      )}

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>
          <AuthModal
            type={auth_type}
            toggle={toggleModal}
            setType={setAuthType}
          />
        </ModalBody>
      </Modal>
    </div>
  );
}
