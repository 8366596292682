import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, CardBody, Col, Row, Spinner } from "reactstrap";
import { addCart, getStockList, updateCart } from "../redux/action/shop";
import { _get } from "../utils/Helper";
import Splide from "./Splide";
import image1 from "../Images/shoe.png";
import image2 from "../Images/bag.png";
import ItemDetails from "./ItemDetails";
import image3 from "../Images/shoe.png";
import "./ShopItems.css";
import ProductDetails from "./ProductDetails";
import ShopHomepage from "./shopHomepage/ShopHomepage";
import lg from "../Images/brands/lg.webp";
import hp from "../Images/brands/hp.webp";
import apple from "../Images/brands/apple.webp";
import cera from "../Images/brands/cera.webp";
import haier from "../Images/brands/haier.webp";
import infinix from "../Images/brands/infinix.webp";
import intel from "../Images/brands/intel.webp";
import itec from "../Images/brands/itec.webp";
import lenovo from "../Images/brands/lenovo.webp";
import ring from "../Images/brands/ring.webp";
import samsung from "../Images/brands/samsung.webp";
import starlink from "../Images/brands/starlink.webp";
import tgi from "../Images/brands/tgi.webp";
import tecno from "../Images/brands/tecno.webp";
import unilever from "../Images/brands/unilever.webp";
import vivo from "../Images/brands/vivo.webp";

import advert from "../Images/advert.webp";
import ProductDet from "./ProductDet";

// method to open new tab

const ShopItems = () => {
  const navigate = useNavigate();
  const { carts, stocks } = useSelector((s) => s.shop);
  const [loading, setLoading] = useState(true);
  const [loadingNew, setLoadingNew] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const getList = useCallback(() => {
    if (stocks.length < 1) {
      dispatch(getStockList(setLoading));
    }
  }, [stocks.length, dispatch]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleItemClick = (item) => {
    navigate(`/item/${item.item_code}`, { state: { item } });
  };

  const addToCart = (it) => {
    dispatch(addCart({ ...it, qty: 1 }));
  };

  const deleteCart = (it) => {
    dispatch(updateCart({ item_code: it.item_code, qty: it.qty - 1 }));
  };

  const addCartItem = (it) => {
    dispatch(updateCart({ item_code: it.item_code, qty: it.qty + 1 }));
  };

  const officialStore = [
    { id: 1, image: lg },
    { id: 2, image: hp },
    { id: 3, image: tecno },
    { id: 4, image: lenovo },
    { id: 5, image: tgi },
    { id: 6, image: haier },
    { id: 7, image: vivo },
    { id: 8, image: unilever },
    { id: 9, image: apple },
    { id: 10, image: cera },
    { id: 11, image: infinix },
    { id: 12, image: intel },
    { id: 13, image: itec },
    { id: 14, image: samsung },
    { id: 15, image: ring },
    { id: 16, image: starlink },
  ];

  const [sliderItems, setSliderItems] = useState([]);

  useEffect(() => {
    setLoadingNew(true);
    setError(null);

    _get(
      `api/getallproducts`,
      (res) => {
        setSliderItems(res.result[0].slice(0, 8));
        setLoadingNew(false);
      },
      (err) => {
        setError(err);
        setLoadingNew(false);
      }
    );
  }, []);

  const first20Items =
    stocks && stocks.filter((itm) => itm.balance > 0).slice(0, 8);

  // code for see more created by phisherman
  const [itemCount, setItemCount] = useState(18);

  const displayedItems =
    stocks && stocks.filter((itm) => itm.balance > 0).slice(0, itemCount);

  const handleSeeMore = () => {
    setItemCount((prevCount) => prevCount + 12);
  };
  // code for see more end

  return (
    <>
      <ShopHomepage />
      <Card className="mt-1 shop-main-card shadow">
        <div className="d-flex  align-items-center">
          <p className="shop-card-title text-start mb-2 mt-0">New Arrivals</p>
        </div>
        <Row>
          <Splide content={sliderItems} loadingNew={loadingNew} href={"#"} />
        </Row>
      </Card>

      <Card className="d-none px-0 border-0 mt-4">
        <CardHeader
          className="official-store w-100 py-1 d-flex align-items-center justify-content-center card-header-no-radius"
          style={{ background: "#36454F", color: "white" }}
        >
          <span className="fw-bold" style={{ fontSize: "18px" }}>
            Official Store
          </span>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="mx-0">
            {officialStore.map((brand) => (
              <Col md={2} sm={4} key={brand.id} className="p-2">
                <img src={brand.image} className="rounded w-100 h-auto" />
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>

      {/* <div className="mt-4">
        <Card className="mt-3 shop-main-card shadow">
          <p className="shop-card-title text-start mb-0 mt-0">
            Top Selling Items
          </p>
          {loading ? (
            <center>
              <Spinner /> Loading, Please wait...
            </center>
          ) : (
            <Row>
              {stocks
                .filter((itm) => itm.balance > 0)
                .map((item, index) => {
                  const selected = carts.find(
                    (a) => a.item_code === item.item_code
                  );
                  return (
                    <Col
                      lg={2}
                      md={3}
                      sm={6}
                      xs={6}
                      key={index}
                      itmmd={2}
                      className="mt-3"
                    >
                      <Card
                        className="item-card mb-3"
                        onClick={() => handleItemClick(item)}
                      >
                        <CardBody className="item-card-body mb-4">
                          <div className="text-center">
                            <img
                              src={Image}
                              alt=""
                              style={{ height: 150, width: 150 }}
                              className="img-fluid" 
                            />
                          </div>
                          <p className="item-name">
                            {item.item_name.length > 16
                              ? `${item.item_name.substring(9, 25)}...`
                              : item.item_name}
                          </p>
                          <p className="item-price">₦{item.unit_price}</p>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })}
              <div className="d-flex justify-content-center">
                <button
                  className="rounded-pill px-4 py-1 border-0 see-more"
                  style={{ color: "#f37021" }}
                  onClick={handleSeeMore}
                >
                  See more
                </button>
              </div>
            </Row>
          )}
        </Card>
      </div> */}
    </>
  );
};
export default ShopItems;
