
import React from 'react'
import { Card, CardTitle, Container } from 'reactstrap'
import './footer.css';
import kasuwalogo from '../../Images/KASUWAMALL (1).png'

const Terms_Cond = () => {
  return (
    <div className='term-div'>
      <Container className='mt-5'>
        <h1>KASUWAMALL Terms & Condition</h1>
        <Card className='term-card'>
          <div className='term-head'>
            <p>Welcome to Kasuwa Mall. By accessing or using our platform, you agree to comply with the following terms and conditions. Please read them carefully before making any purchases or listing any products.</p>
          </div>
          <div className='logo_cont'></div>
        </Card>
        <div className='container p-4'>
          <h3 className='h3-term'>1. Introduction</h3>
          <p className='term-p'>
            These Terms and Conditions govern your use of our e-commerce platform, including the display and sale of products by vendors and the logistics services we offer.
          </p>
          <h3 className='h3-term'>2. Eligibility</h3>
          <p className='term-p'>
            By using this website, you confirm that you are at least 18 years of age or have received permission from a parent or legal guardian to enter into transactions on the site.
          </p>
          <h3 className='h3-term'>3. Vendor Responsibilities</h3>
          <p className='term-p'>
            Vendors are responsible for ensuring the accuracy, legality, and quality of the products they list on the platform.
Vendors must provide complete and truthful information about their products, including but not limited to price, description, and stock availability.
Vendors are solely responsible for complying with all applicable laws and regulations regarding the sale of their products.

          </p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
          <h3 className='h3-term'></h3>
          <p className='term-p'></p>
        </div>
      </Container>
    </div>
  )
}

export default Terms_Cond