import React, { useState } from "react";
import { Trash, CheckCircle } from "react-feather";
import {
  Badge,
  Button,
  Card,
  Col,
  Modal,
  ModalBody,
  Row,
  Table,
  CardHeader,
  CardFooter,
} from "reactstrap";

import visa from "../Images/visa.png";
import master from "../Images/master.png";
import verve from "../Images/verve.png";
import CartCard from "./CartCard";
import { useDispatch, useSelector } from "react-redux";
import AuthModal from "../Auths/AuthModal";
import { _post } from "../utils/Helper";
import { deleteCarts } from "../redux/action/shop";
import { FaArrowLeft } from "react-icons/fa";
import CartTable from "./CartTable";
import "./shopcart.css";
import { useNavigate } from "react-router";
import { separator } from "../utils/Helper";

export default function ShopCart() {
  const {
    shop: { carts },
  } = useSelector((s) => s);
  const dispatch = useDispatch();
  const [auth_type, setAuthType] = useState("Login");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleclick = () => {
    if (!carts.length) {
      alert("Your cart is empty! Please add items before submitting.");
      return;
    }

    setIsSubmitting(true);
    const data = carts.map((dt) => ({
      customer_id: localStorage.getItem("@@toke_$$_45598"),
      product: dt.product_name,
      quantity: dt.qty,
      product_id: dt.id,
      status: "Pending",
      shop_id: dt.shop_id,
      order_image: dt.selectedImage,
    }));

    _post(
      "api/createorders",
      { products: data },
      (res) => {
        if (res.success) {
        console.log("Order created successfully:", res);
        setIsSubmitting(false);
        alert("Order successfully placed!");
        dispatch(deleteCarts());
        } else {
          console.log("failed to create order", res)
          alert(res.message)
        }
      },
      (err) => {
        console.error("Failed to place order:", err);
        alert("Failed to place order. Please try again.");
        setIsSubmitting(false);
      }
    );
  };

  const handlenavigate = () => {
    navigate("/");
  };

  const handleButtonClick = () => {
    if (isLoggedIn) {
      handleclick(); 
    } else {
      toggle(); 
    }
  };

  const handleLoginSuccess = () => {
    setIsLoggedIn(true); 
    toggle(); 
  };

  return (
    <>
      <div className="container extra-margin-top" style={{ paddingBottom: 20 }}>
        <header>
          <h1>Shopping Cart</h1>
        </header>

        <div className="mt-3 mb-4">
          <Button onClick={handlenavigate} className="cont-bnt">
            <FaArrowLeft /> Continue Shopping
          </Button>
        </div>

        <Row className="mt-3">
          <Col md={9}>
            <CartTable />
          </Col>
          <Col md={3}>
            <Card className="shadow p-3 cart-right-card">
              <CardHeader className="d-flex justify-content-between">
                <h3 style={{ fontSize: "15px" }}>Order Summary</h3>
                <h3 style={{ fontSize: "15px" }}>
                  {carts.length && carts.reduce((p, c) => p + c.qty, 0)} Items
                </h3>
              </CardHeader>
              <Row className="mt-4">
                <Col md={7}>
                  <h6 style={{ fontSize: "12px", padding: "0", margin: "0" }}>
                    Delivery charges
                  </h6>
                </Col>
                <Col md={5}>
                  <p
                    style={{
                      fontSize: "10px",
                      textAlign: "right",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    Add your Delivery address at checkout to see delivery
                    charges
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mt-1">
                <Col md={7}>
                  <h6 style={{ fontSize: "12px", padding: "0", margin: "0" }}>
                    Subtotal
                  </h6>
                </Col>
                <Col md={5}>
                  <h4
                    style={{
                      fontSize: "10px",
                      textAlign: "right",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    &#8358;{" "}
                    {separator(
                      carts.length &&
                        carts.reduce((p, c) => p + c.qty * c.product_price, 0)
                    )}
                  </h4>
                </Col>
              </Row>
              <hr />
              <Row className="mt-1">
                <Col md={7}>
                  <h6
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    Total
                  </h6>
                </Col>
                <Col md={5}>
                  <h4
                    style={{
                      fontSize: "18px",
                      fontWeight: "700",
                      textAlign: "right",
                      padding: "0",
                      margin: "0",
                    }}
                  >
                    &#8358;{" "}
                    {separator(
                      carts.length &&
                        carts.reduce((p, c) => p + c.qty * c.product_price, 0)
                    )}
                  </h4>
                </Col>
              </Row>
              <hr />
              <div>
                <p
                  className="text-danger"
                  style={{ fontSize: "10px", textAlign: "right" }}
                >
                  Excluding delivery charges
                </p>
              </div>
              <Button
                onClick={handleButtonClick}
                className="check mb-0"
                // disabled={isSubmitting}
              >
                {isLoggedIn ? "Submit Order" : "Continue"}
              </Button>
              <CardFooter>
                <p className="" style={{ fontSize: "13px" }}>
                  We accept :{" "}
                  <img style={{ width: "40px" }} alt="Master" src={master} />{" "}
                  <img style={{ width: "40px" }} alt="Visa" src={visa} />{" "}
                  <img style={{ width: "40px" }} alt="Verve" src={verve} />{" "}
                </p>
              </CardFooter>
              <Modal isOpen={modal} toggle={toggle}>
                <ModalBody>
                  <AuthModal
                    type={auth_type}
                    toggle={toggle}
                    setType={setAuthType}
                    onLoginSuccess={handleLoginSuccess}
                  />
                </ModalBody>
              </Modal>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}
