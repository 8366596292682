import React from "react";
import { Table, Row, Col, Button, Badge } from "reactstrap";
import { Trash } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { deleteCart, updateCart } from "../redux/action/shop";
import "./carttable.css";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { separator } from "../utils/Helper";

const CartTable = () => {
  const { carts } = useSelector((state) => state.shop);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCartItem = (item) => {
    if (item.qty <= 1) {
      dispatch(deleteCart(item));
    } else {
      dispatch(updateCart({ item_code: item.item_code, qty: item.qty - 1 }));
    }
  };

  const removeCart = (item) => {
    dispatch(deleteCart(item));
  };

  const addCartItem = (item) => {
    dispatch(updateCart({ item_code: item.item_code, qty: item.qty + 1 }));
  };

  // Navigate back to home
  const handleBackToHome = () => {
    navigate(-1);
  };

  return (
    <div className="cart-container">
      {/* <Button onClick={handleBackToHome} className="cont-bnt mb-3">
        <FaArrowLeft />
        Back to Home
      </Button> */}

      <Table responsive className="shadow bg-light">
        <thead className="bg-dark">
          <tr className="bg-dark">
            <th className="text-center">Item Details</th>
            <th className="">Quantity</th>
            <th>Item Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {carts.length ? (
            carts.map((item, idx) => (
              <tr key={idx}>
                <td style={{ display: "flex", gap: "10px" }}>
                  <img
                    src={item.selectedImage}
                    style={{
                      width: "70px",
                      height: "70px",
                    }}
                  />
                  <p style={{ textTransform: "capitalize" }}>
                    {item.product_name}
                  </p>
                </td>
                <td className="text-center">
                  <div className="d-flex align-items-center justify-content-ceneter">
                    <Button
                      onClick={() => deleteCartItem(item)}
                      style={{
                        background: "#f2eadb",
                        color: "#542b2b",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      -
                    </Button>
                    <Badge
                      className="p-3 mx-1"
                      style={{ background: "#542b2b" }}
                    >
                      {item.qty}
                    </Badge>
                    <Button
                      onClick={() => addCartItem(item)}
                      style={{
                        background: "#f2eadb",
                        color: "#542b2b",
                        fontWeight: "600",
                        fontSize: "15px",
                      }}
                    >
                      +
                    </Button>
                  </div>
                </td>
                <td>₦{separator(item.product_price)}</td>
                <td>
                  <Button onClick={() => removeCart(item)} color="danger">
                    <Trash />
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                No items in the cart
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default CartTable;
